import logo from './logo.svg';
import './App.css';
import './Main.css';
import './Media.css';
import React from 'react';



function Main(props) {
    
    let keys = localStorage.getItem("key_s");

    if(!keys){
        console.log(keys);
        window.location.href = "/";        
    }
    else{
        console.log(keys);
    }
    var objAllApi;
    var contentHead;
    var contentAddMain;
    var contentDataMain = [];
    var contentDataUsers = [];
    var contentDataTypeMain = [];
    var contentDataCounterparty = [];
    var contentDataApplicant = [];
    var contentDataComments = [];
    var contentDataPriority = [];
    var contentDataStatus = [];
    var contentDataHead;
    let xhr = new XMLHttpRequest();
    //xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.open("GET", "https://elec-commerc.com/static/api/addmain/?keys="+keys, false);
    //xhr.responseType = "json";
    try {
        xhr.send();
        if (xhr.status != 200) {
            console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`);
        } else {
            objAllApi = JSON.parse(xhr.response);
            contentHead = objAllApi.user.roll;
            contentAddMain = objAllApi.user.roll;
            contentDataMain = objAllApi.application;
            contentDataUsers = objAllApi.users;
            contentDataTypeMain = objAllApi.type_application[0];
            contentDataCounterparty = objAllApi.counterparty[0];
            contentDataCounterparty = objAllApi.counterparty[0];
            contentDataApplicant = objAllApi.applicant[0];
            contentDataComments = objAllApi.comments[0];
            contentDataPriority = objAllApi.priority[0];
            contentDataStatus = objAllApi.status[0];
            console.log(objAllApi);
            //console.log(JSON.parse(objAllApi));
        }
    } catch(err) { // для отлова ошибок используем конструкцию try...catch вместо onerror
            console.log("Запрос не удался");
    }

    function dataSetMenu(event){
        window.location.href = "/"+event.target.getAttribute("data-id")+"/"; 
    }
    function dataSet(event){
        console.log(event.target.getAttribute('data-main-id'));
        console.log(event.target.getAttribute('data-id'));
        console.log(contentDataComments);
        if(event.target.getAttribute('data-main-id') == "comment"){
            //data-content="data-content"
            const dialog = document.getElementById('myDialog')
            const dialogOpener = document.querySelector('.openDialogBtn')
            const dialogCloser = dialog.querySelector('.closeDialogBtn')
            dialog.showModal()
            document.body.classList.add('scroll-lock')
            let getComment = [];
            getComment = contentDataComments.filter(function(comment) {
                return comment.application_id === event.target.getAttribute('data-id');
            });
            let content = "";
            if(getComment){
                for (let i = 0; i < getComment.length; i++) { 
                    content += "<div class='comments'>"+getComment[i].comment+"</div > " ;
                }
                content += "<div class='comments-nw-text'><textarea></textarea></div > ";
                console.log(content);
                document.querySelector('[data-content="data-content"]').innerHTML = content;

            }
            
            //xhr.open("GET", "https://elec-commerc.com/static/api/datausers/?keys="+keys+"&update=user&tbid="+event.target.getAttribute("data-users-id")+url, false);
//
            //try {
            //    xhr.send();
            //    if (xhr.status != 200) {
            //        console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`);
            //    } else {
            //        window.location.href = "/users/"; 
            //    }
            //} catch(err) { // для отлова ошибок используем конструкцию try...catch вместо onerror
            //        console.log("Запрос не удался");
            //}


        }
        //ata-main-id="comment" data-id
    }
    function dataNewComments(event){

    }

    function dataSetComment(event){

    }

    function closePopup(event){
        
        const dialog = document.getElementById('myDialog')
        const dialogOpener = document.querySelector('.openDialogBtn')
        const dialogCloser = dialog.querySelector('.closeDialogBtn')
        dialog.close()
        document.body.classList.remove('scroll-lock')
    }

    function dataMe(event){
        console.log(event.target);

        //setName(event.target.value);  
    }
    function dataAddMain(event){
        window.location.href = "/"+event.target.getAttribute("data-id")+"/"; 
    }
    function dataExit(event) {
        localStorage.removeItem("key_s");
        window.location.href = "/";
    }
    function dataMenuMobile(event){
        //console.log(document.getElementById("menu-mob") );
        let atr = document.getElementById("menu-mob") ;
        //(atr.getAttribute("data-menu-mobile") );
        if(atr.getAttribute("data-menu-mobile") == "close"){
            atr.dataset.menuMobile = "open";
            atr.innerHTML = "X";
            document.getElementById("header-control").style.display = "flex";
        }else{
            atr.dataset.menuMobile = "close";
            atr.innerHTML = "&#9776;";
            document.getElementById("header-control").style.display = "none";
        }
    }

    let getHead = contentHead => {
        //console.log(contentHead);
        let content = [];
        if(contentHead == 0 || contentHead == 5 || contentHead == 6 ){
            content.push( 
                <ul class="jam-ul-header">    
                    <li class="jam-li-header"> <div class="jam-button" data-id="main" onClick={dataSetMenu} > <div data-id="main" class="jam-div-button">Заявки</div></div> </li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="roll" onClick={dataSetMenu} > <div data-id="roll" class="jam-div-button">Роли</div></div> </li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="subdivision" onClick={dataSetMenu} > <div data-id="subdivision" class="jam-div-button">Подразделения</div></div></li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="users" onClick={dataSetMenu} > <div data-id="users" class="jam-div-button">Сотрудники</div></div></li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="applicant" onClick={dataSetMenu} > <div data-id="applicant" class="jam-div-button">Заявители</div></div></li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="counterparty" onClick={dataSetMenu} > <div data-id="counterparty" class="jam-div-button">Контрагенты</div></div></li>
                    <li class="jam-li-header">  <input class="jam-input" type="text" placeholder="поиск" /> </li>
                </ul>
            );
        }else{
            content.push( 
                <ul class="jam-ul-header">    
                    <li class="jam-li-header">  <input class="jam-input" type="text" placeholder="поиск" /> </li>
                </ul>
            );
        }
        return content;
    }

    let getAddMain = contentAddMain => {
        //console.log(contentAddMain);
        let content = [];
        if(contentHead == 0 || contentHead == 5 || contentHead == 6){
            content.push( 
                <div class="jam-bottom">
                    <ul class="jam-ul-bottom">
                        <li class="jam-li-header"> <div data-id="addmain" class="jam-button" onClick={dataAddMain} > <div data-id="addmain" class="jam-div-button">Добавить заявку</div></div> </li> 
                    </ul>
                </div>
            );
        }
        return content;
    }
    let getContentHead = contentDataHead => { 
        let content = [];
        content.push(             

                <tr>
                    <th>Номер заявки</th>
                    <th>Дата/Время регистрации</th>
                    <th>Постановщик</th>
                    <th>Тип заявки</th>
                    <th>Контрагаент</th>
                    <th>Номер и Дата договора</th>
                    <th>Заявитель</th>
                    <th>Комментарий</th>
                    <th>Согласованный срок</th>
                    <th>Приоритет</th>
                    <th>Статус заявки</th>
                    <th>Назанченный исполнитель</th>
                </tr>

        );
        return content;
    }


    let getContent = contentDataMain => {
        let content = [];

        for (let i = 0; i < contentDataMain[0].length; i++) {
            const item = contentDataMain[0][i];
            let getUser = [];
            getUser = contentDataUsers.filter(function(user) {
                return user.id === item.user_add_id;
            })[0];
            let getType = [];
            getType = contentDataTypeMain.filter(function(type) {
                return type.id === item.type_application_id;
            })[0];
            let getCpt = [];
            getCpt = contentDataCounterparty.filter(function(cpt) {
                return cpt.id === item.counterparty_id;
            })[0];
            let getApplicant = [];
            getApplicant = contentDataApplicant.filter(function(applicant) {
                return applicant.id === item.applicant_id;
            })[0];
            let getComment = [];
            getComment = contentDataComments.filter(function(comment) {
                return comment.application_id === item.id;
            })[0];
            console.log(getComment);
            let comment;
            if(getComment !== undefined){
                comment = getComment.comment;
            }else{
                comment = "";
            }
            let getPriority = [];
            getPriority = contentDataPriority.filter(function(priority) {
                return priority.id === item.priority_id;
            })[0];
            let getStatus = [];
            getStatus = contentDataStatus.filter(function(status) {
                return status.id === item.status_id;
            })[0];
            let getUserId = [];
            getUserId = contentDataUsers.filter(function(user) {
                return user.id === item.user_id;
            })[0];
            content.push(

                <tr onClick={dataSet}><td data-main-id="id">{item.id}</td>
                    <td data-main-id="date"  >{item.date_add}</td>
                    <td data-main-id="user_add_id" >{getUser.name+" "+getUser.patronymic+" "+getUser.surname}</td>
                    <td data-main-id="type_application_id" >{getType.name}</td>
                    <td data-main-id="counterparty_id" >{getCpt.name}</td>
                    <td data-main-id="date_contract" >{getCpt.date_contract}</td>
                    <td data-main-id="fio_applicant" >{getApplicant.fio}</td>
                    <td data-main-id="comment" data-id={item.id} >{comment}</td>
                    <td data-main-id="agreedpart" >{item.agreedpart}</td>
                    <td data-main-id="priority_id" >{getPriority.name}</td>
                    <td data-main-id="status_id" >{getStatus.name}</td>
                    <td data-main-id="user_id" >{getUserId.name+" "+getUserId.patronymic+" "+getUserId.surname}</td>
                </tr>

            );
            
          
        }
        return content;
    }

    

    return (
    <div>
        <header id="header-control" class="jam-header">
            {getHead(contentHead)}

            <ul class="jam-ul-header">
                <li class="jam-li-header"> <div class="jam-button" data-id="me" onClick={dataMe} > <div class="jam-div-button">{objAllApi.user.name} {objAllApi.user.surname}</div></div><img class="jam-img-icon-header" src="https://avatars.mds.yandex.net/i?id=4f7586d49edaa427e07a8819562fc284_l-5248434-images-thumbs&n=13" /></li> 
                <li class="jam-li-header"> <div class="jam-button" onClick={dataExit} > <div class="jam-div-button">Выход</div></div> </li> 
            </ul>
        </header>
        <div class="jam-header-control">
            <div class="jam-header-control-op">
                <div class="jam-button-header" onClick={dataMenuMobile} > <div id="menu-mob" data-menu-mobile="close" class="jam-div-button">&#9776;</div></div>
            </div>
        </div>
        <div class="jam-main">
            <div class="mobile-table">
                <table class="iksweb">
                <thead>
                {getContentHead(contentDataHead)}
                </thead>
                <tbody>
                {getContent(contentDataMain)}   
                </tbody>
                </table>
                </div>
            </div>
                {getAddMain(contentAddMain)}

        <div>

        </div>

        <dialog class="child" id="myDialog">
            <div class="dialog__wrapper">

            <div data-content="data-content">
            </div>
            <button onClick={dataSetComment}>
                Добавить
            </button>
            <button onClick={closePopup}>
                Закрыть
            </button>
            </div>
        </dialog>
    </div>
    );
}

export default Main;
