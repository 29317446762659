import logo from './logo.svg';
import './App.css';
import './Main.css';
import './Media.css';
import './Subdivision.css';
import React from 'react';

function Subdivision(props) {
    
    let keys = localStorage.getItem("key_s");

    if(!keys){
        console.log(keys);
        window.location.href = "/";        
    }
    else{
        console.log(keys);
    }
    var objAllApi;
    var contentHead;
    var contentAddMain;
    var contentDataMain;
    let xhr = new XMLHttpRequest();
    //xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.open("GET", "https://elec-commerc.com/static/api/datasubdiv/?keys="+keys, false);
    //xhr.responseType = "json";
    try {
        xhr.send();
        if (xhr.status != 200) {
            console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`);
        } else {
            objAllApi = JSON.parse(xhr.response);
            contentHead = objAllApi.user.roll;
            contentAddMain = objAllApi.user.roll;
            contentDataMain = objAllApi.subdivision;
            console.log(objAllApi);
            //console.log(JSON.parse(objAllApi));
        }
    } catch(err) { // для отлова ошибок используем конструкцию try...catch вместо onerror
            console.log("Запрос не удался");
    }

    function dataSetMenu(event){
        window.location.href = "/"+event.target.getAttribute("data-id")+"/"; 
        console.log(event.target.getAttribute("data-id"));
    }
    function dataSet(event){
        console.log(props.objReg[0].apiSess);
    }

    function dataMe(event){
        console.log(event.target);
        //setName(event.target.value);  
    }
    
    function dataExit(event) {
        localStorage.removeItem("key_s");
        window.location.href = "/";
    }
    function dataMenuMobile(event){
        console.log(document.getElementById("menu-mob") );
        let atr = document.getElementById("menu-mob") ;
        console.log(atr.getAttribute("data-menu-mobile") );
        if(atr.getAttribute("data-menu-mobile") == "close"){
            atr.dataset.menuMobile = "open";
            atr.innerHTML = "X";
            document.getElementById("header-control").style.display = "flex";
        }else{
            atr.dataset.menuMobile = "close";
            atr.innerHTML = "&#9776;";
            document.getElementById("header-control").style.display = "none";
        }
    }
    function addSubdivision(event){
        console.log( document.querySelector("[data-new-subdiv='true']").value );
        if(document.querySelector("[data-new-subdiv='true']")){
            if(document.querySelector("[data-new-subdiv='true']").value !== ""){
                xhr.open("GET", "https://elec-commerc.com/static/api/datasubdiv/?t=subdivision&add="+document.querySelector("[data-new-subdiv='true']").value, false);
                //xhr.responseType = "json";
                try {
                    xhr.send();
                    if (xhr.status != 200) {
                        console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`);
                    } else {
                        objAllApi = JSON.parse(xhr.response);
                        console.log(objAllApi);
                        window.location.href = "/subdivision/"; 
                    }
                } catch(err) { // для отлова ошибок используем конструкцию try...catch вместо onerror
                    console.log("Запрос не удался");
                }
            }
        }
    }

    function saveSubdivision(event){
        
        console.log(event.target.getAttribute("data-subdiv-id"))


        console.log(document.querySelector("[data-subdiv='"+event.target.getAttribute("data-subdiv-id")+"']").value)
        document.querySelector("[data-subdiv='"+event.target.getAttribute("data-subdiv-id")+"']")

        if(document.querySelector("[data-subdiv='"+event.target.getAttribute("data-subdiv-id")+"']").value !==""){
            xhr.open("GET", "https://elec-commerc.com/static/api/datasubdiv/?t=subdivision&update="+document.querySelector("[data-subdiv='"+event.target.getAttribute("data-subdiv-id")+"']").value+"&idsubdiv="+event.target.getAttribute("data-subdiv-id"), false);
            try {
                xhr.send();
                if (xhr.status != 200) {
                    console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`);
                } else {
                    objAllApi = JSON.parse(xhr.response);
                    console.log(objAllApi);
                    window.location.href = "/subdivision/"; 
                }
            } catch(err) { // для отлова ошибок используем конструкцию try...catch вместо onerror
                console.log(err);
                console.log("Запрос не удался");
            }
        }
        
    }

    let getHead = contentHead => {
        console.log(contentHead);
        let content = [];
        if(contentHead != 4){
            content.push( 
                <ul class="jam-ul-header">    
                    <li class="jam-li-header"> <div class="jam-button" data-id="main" onClick={dataSetMenu} > <div data-id="main" class="jam-div-button">Заявки</div></div> </li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="roll" onClick={dataSetMenu} > <div data-id="roll" class="jam-div-button">Роли</div></div> </li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="subdivision" onClick={dataSetMenu} > <div data-id="subdivision" class="jam-div-button">Подразделения</div></div></li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="users" onClick={dataSetMenu} > <div data-id="users" class="jam-div-button">Сотрудники</div></div></li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="applicant" onClick={dataSetMenu} > <div data-id="applicant" class="jam-div-button">Заявители</div></div></li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="counterparty" onClick={dataSetMenu} > <div data-id="counterparty" class="jam-div-button">Контрагенты</div></div></li>
                    <li class="jam-li-header">  <input class="jam-input" type="text" placeholder="поиск" /> </li>
                </ul>
            );
        }
        return content;
    }

    let getAddMain = contentAddMain => {
        console.log(contentAddMain);
        let content = [];
        if(contentAddMain != 4){
            content.push( 
                <div class="jam-bottom">
                    <ul class="jam-ul-bottom">
                        <li class="jam-li-header"> <div class="jam-button" onClick={dataExit} > <div class="jam-div-button">Добавить</div></div> </li> 
                    </ul>
                </div>
            );
        }
        return content;
    }

    let getContent = contentDataMain => {
        console.log(contentDataMain.length);
        let content = [];
        content.push(             
            <thead>
                <tr>
                    <th>Подразделение</th>
                </tr>
            </thead>
        );
        for (let i = 0; i < contentDataMain[0].length; i++) {
            const item = contentDataMain[0][i];
            console.log(item.name);
            content.push(
                <tbody>
                    <tr><td><div class="jam-tb-control"> <input data-subdiv={item.id} class="jam-input-tb" defaultValue={item.name}/> <div data-subdiv-id={item.id} class="jam-button-tb" onClick={saveSubdivision} > <div data-subdiv-id={item.id} class="jam-tb-button">Сохранить</div></div> </div></td>
                    </tr>
                </tbody>
            );
        }
        content.push(
            <tbody>
                <tr><td><div class="jam-tb-control"> <input data-new-subdiv  class="jam-input-tb"/> <div class="jam-button-tb" onClick={addSubdivision} > <div class="jam-tb-button">Добавить</div></div> </div></td> 
                </tr>
            </tbody>
        );
        return content;
    }

    

    return (
    <div>
        <header id="header-control" class="jam-header">
            {getHead(contentHead)}

            <ul class="jam-ul-header">
                <li class="jam-li-header"> <div class="jam-button" data-id="me" onClick={dataMe} > <div class="jam-div-button">{objAllApi.user.name} {objAllApi.user.surname}</div></div><img class="jam-img-icon-header" src="https://avatars.mds.yandex.net/i?id=4f7586d49edaa427e07a8819562fc284_l-5248434-images-thumbs&n=13" /></li> 
                <li class="jam-li-header"> <div class="jam-button" onClick={dataExit} > <div class="jam-div-button">Выход</div></div> </li> 
            </ul>
    </header>
    <div class="jam-header-control">
            <div class="jam-header-control-op">
                <div class="jam-button-header" onClick={dataMenuMobile} > <div id="menu-mob" data-menu-mobile="close" class="jam-div-button">&#9776;</div></div>
            </div>
        </div>
    <div class="jam-main">
        <div class="mobile-table">
            <table class="iksweb">
            {getContent(contentDataMain)}

            </table>
            </div>
        </div>
        <div> 
        </div>
    </div>
    );
}

export default Subdivision;
