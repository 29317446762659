import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ValidRegForm from './ValidForm.js';
import Main from './Main.js';
import Roll from './Roll.js';
import Subdivision from './Subdivision.js';
import AddMain from './AddMain.js';
import Users from './Users.js';
import Counterparty from './Counterparty.js';
import Applicant from './Applicant.js';
import Applicationadd from './Applicationadd.js';

import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
console.log("test");
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Router>
    <Routes>
        <Route path="/" element={<ValidRegForm  />} />
        <Route path="/main" element={<Main  />} />
        <Route path="/addmain/" element={<AddMain  />} />
        <Route path="/roll" element={<Roll  />} />
        <Route path="/subdivision" element={<Subdivision  />} />
        <Route path="/users" element={<Users  />} />
        <Route path="/counterparty" element={<Counterparty  />} />
        <Route path="/applicant" element={<Applicant  />} />
        <Route path="/applicationadd" element={<Applicationadd  />} />
    </Routes>
</Router> 
);


