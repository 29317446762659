import logo from './logo.svg';
import './App.css';
import './Main.css';
import './Media.css';
import './AddMain.css';
import React from 'react';

function AddMain(props) {
    
    let keys = localStorage.getItem("key_s");

    if(!keys){
       
        window.location.href = "/";        
    }
    else{
       
    }
    var contentDataCounter = [ 
        ["Наименование","name",""],
        ["Дата договора","date_contract","date"],
        ["Номер договора","number_contract",""],
    ];
    
    var contentDataApplicant = [ 
        ["ФИО:","fio",""],
        ["Должность","jobtitle",""],
        ["Телефон","phone",""],
    ];
    var contentDataApplication = [ 
        ["Дата:","date_add","date"],
        ["Тип заявки","type_application_id","select"],
        ["Комментарий","comment",""],
        ["Дата выполнения","agreedpart","date"],
        ["Приоритет","priority_id","select"],
        ["Статус заявки","status_id","select"],
        ["Исполнитель","user_id","select"],
        ["Подразделение","subdivision_id","select"],
    ];
  
    var objAllApi;
    var contentHead;
    var contentAddMain;
    var contentDataMain = [];
    var contentDataSelectPrioritet;
    var contentDataSelectStatus;
    var contentDataSelectUser; 
    var contentDataSelectSubdivision;
    var contentDataSelectTypeApplication;
    let xhr = new XMLHttpRequest();

    xhr.open("GET", "https://elec-commerc.com/static/api/addmain/?keys="+keys, false);

    try {
        xhr.send();
        if (xhr.status != 200) {
            console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`);
        } else {
            objAllApi = JSON.parse(xhr.response);
            console.log(objAllApi);
            contentHead = objAllApi.user.roll;
            contentAddMain = objAllApi.user.roll;
            contentDataSelectPrioritet = objAllApi.priority;
            contentDataSelectStatus = objAllApi.status;
            contentDataSelectUser = objAllApi.users;
            contentDataSelectSubdivision = objAllApi.subdivision;
            contentDataSelectTypeApplication = objAllApi.type_application;
        }
    } catch(err) { // для отлова ошибок используем конструкцию try...catch вместо onerror
            console.log("Запрос не удался");
    }

    function dataSetMenu(event){
        window.location.href = "/"+event.target.getAttribute("data-id")+"/"; 

        
       
    }
    function dataSet(event){

    }

    function dataMe(event){


    }
    
    function dataExit(event) {
        localStorage.removeItem("key_s");
        window.location.href = "/";
    }
    function dataAdd(event){

    }

    let getHead = contentHead => {

        let content = [];
        if(contentHead == 0 || contentHead == 5 || contentHead == 6){
            content.push( 
                <ul class="jam-ul-header">    
                    <li class="jam-li-header"> <div class="jam-button" data-id="main" onClick={dataSetMenu} ><div data-id="main" class="jam-div-button">Заявки</div></div> </li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="roll" onClick={dataSetMenu} ><div data-id="roll" class="jam-div-button">Роли</div></div> </li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="subdivision" onClick={dataSetMenu} ><div data-id="subdivision" class="jam-div-button">Подразделения</div></div></li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="users" onClick={dataSetMenu} ><div data-id="users" class="jam-div-button">Сотрудники</div></div></li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="applicant" onClick={dataSetMenu} ><div data-id="applicant" class="jam-div-button">Заявители</div></div></li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="counterparty" onClick={dataSetMenu} ><div data-id="counterparty" class="jam-div-button">Контрагенты</div></div></li>
                    <li class="jam-li-header">  <input class="jam-input" type="text" placeholder="поиск" /> </li>
                </ul>
            );
        }else{
            content.push( 
                <ul class="jam-ul-header">    
                    <li class="jam-li-header">  <input class="jam-input" type="text" placeholder="поиск" /> </li>
                </ul>
            );
        }
        return content;
    }



    let getContentCounter = contentDataCounter => {
  
        let content = [];
        for (let i = 0; i < contentDataCounter.length; i++) {

            content.push(
            
                    <tr><td><div class="jam-td-mob-name">{contentDataCounter[i][0]}:</div><div class="jam-tb-control"><input type={contentDataCounter[i][2]} data-add={contentDataCounter[i][1]} class="jam-input-tb" /></div></td></tr>
    
            );
        }
        return content;
    }

    let getContentApplicant = contentDataApplicant => {
        let content = [];
        for (let i = 0; i < contentDataApplicant.length; i++) {
            content.push(
            
                    <tr><td><div class="jam-td-mob-name">{contentDataApplicant[i][0]}:</div><div class="jam-tb-control"><input type={contentDataApplicant[i][2]} data-add={contentDataApplicant[i][1]} class="jam-input-tb" /></div></td></tr>
    
            );
        }

        return content;
    }


    let getSelectPrioritet = contentDataSelectPrioritet => { 
        let content = []; 
        for (let i = 0; i < contentDataSelectPrioritet[0].length; i++) { 
           
            content.push ( <option data-id={contentDataSelectPrioritet[0][i].id} value={contentDataSelectPrioritet[0][i].id} >{contentDataSelectPrioritet[0][i].name}</option> ); 
        } 
        return content; 
    }

    let getSelectStatus = contentDataSelectStatus => { 
        let content = []; 
        for (let i = 0; i < contentDataSelectStatus[0].length; i++) { 
            
            content.push ( <option data-id={contentDataSelectStatus[0][i].id} value={contentDataSelectStatus[0][i].id} >{contentDataSelectStatus[0][i].name}</option> ); 
        } 
        return content; 
    }
    
    let getSelectUser = contentDataSelectUser => { 
        let content = []; 
        for (let i = 0; i < contentDataSelectUser.length; i++) { 
            console.log(contentDataSelectUser[i]);
            content.push ( <option data-id={contentDataSelectUser[i].id} value={contentDataSelectUser[i].id} >{contentDataSelectUser[i].name+" "+contentDataSelectUser[i].patronymic+" "+contentDataSelectUser[i].surname}</option> ); 
        } 
        return content; 
    }

    let getSelectSubdivision = contentDataSelectSubdivision => { 
        let content = []; 
        for (let i = 0; i < contentDataSelectSubdivision[0].length; i++) { 
            
            content.push ( <option data-id={contentDataSelectSubdivision[0][i].id} value={contentDataSelectSubdivision[0][i].id} >{contentDataSelectSubdivision[0][i].name}</option> ); 
        } 
        return content; 
    }

    let getSelectTypeApplication = contentDataSelectTypeApplication => { 
        let content = []; 
        for (let i = 0; i < contentDataSelectTypeApplication[0].length; i++) { 
            
            content.push ( <option data-id={contentDataSelectTypeApplication[0][i].id} value={contentDataSelectTypeApplication[0][i].id} >{contentDataSelectTypeApplication[0][i].name}</option> ); 
        } 
        return content; 
    }

    let   getContentApplication = contentDataApplication => {
        let content = [];
        for (let i = 0; i < contentDataApplication.length; i++) {
            if(contentDataApplication[i][2] == "date"){
                content.push(
            
                    <tr><td><div class="jam-td-mob-name">{contentDataApplication[i][0]}:</div><div class="jam-tb-control"><input type={"date"} data-add={contentDataApplication[i][1]} class="jam-input-tb" /></div></td></tr>
    
                );
            }
            
            if(contentDataApplication[i][2] == ""){
                if(contentDataApplication[i][1] == "comment"){
                    content.push(
                    
                        <tr><td><div class="jam-td-mob-name">{contentDataApplication[i][0]}:</div><div class="jam-tb-control"><textarea type={"date"} data-add={contentDataApplication[i][1]} class="jam-input-tb" /></div></td></tr>
                    
                    );
                }
            }
             //status_id user_id subdivision_id type_application_id
            if(contentDataApplication[i][2] == "select"){
                if(contentDataApplication[i][1] == "priority_id"){
                    content.push(
                        <tr><td><div class="jam-td-mob-name">{contentDataApplication[i][0]}:</div><div class="jam-tb-control"> <select data-add={contentDataApplication[i][1]} class="jam-tb-select"> {getSelectPrioritet(contentDataSelectPrioritet)} </select> </div></td></tr>
                    );
                }
                if(contentDataApplication[i][1] == "status_id"){
                    content.push(
                        <tr><td><div class="jam-td-mob-name">{contentDataApplication[i][0]}:</div><div class="jam-tb-control"> <select data-add={contentDataApplication[i][1]} class="jam-tb-select"> {getSelectStatus(contentDataSelectStatus)} </select> </div></td></tr>
                    );
                }
                if(contentDataApplication[i][1] == "user_id"){
                    content.push(
                        <tr><td><div class="jam-td-mob-name">{contentDataApplication[i][0]}:</div><div class="jam-tb-control"> <select data-add={contentDataApplication[i][1]} class="jam-tb-select"> {getSelectUser(contentDataSelectUser)} </select> </div></td></tr>
                    );
                }
                if(contentDataApplication[i][1] == "subdivision_id"){
                    content.push(
                        <tr><td><div class="jam-td-mob-name">{contentDataApplication[i][0]}:</div><div class="jam-tb-control"> <select data-add={contentDataApplication[i][1]} class="jam-tb-select"> {getSelectSubdivision(contentDataSelectSubdivision)} </select> </div></td></tr>
                    );
                }
                if(contentDataApplication[i][1] == "type_application_id"){
                    content.push(
                        <tr><td><div class="jam-td-mob-name">{contentDataApplication[i][0]}:</div><div class="jam-tb-control"> <select data-add={contentDataApplication[i][1]} class="jam-tb-select"> {getSelectTypeApplication(contentDataSelectTypeApplication)} </select> </div></td></tr>
                    );
                }
            }
            

        }
        return content;
    }

    function addMain(event){
        let goUrl = true;
        let url = "";
        for (let i = 0; i < contentDataCounter.length; i++) { 
            
            if(document.querySelector("[data-add='"+contentDataCounter[i][1]+"']").value == ""){
                console.log(contentDataCounter[i][1]);
                document.querySelector("[data-add='"+contentDataCounter[i][1]+"']").style.backgroundColor = "red";
                goUrl = false;
            }else{
                url += "&"+contentDataCounter[i][1]+"="+document.querySelector("[data-add='"+contentDataCounter[i][1]+"']").value;
            }
        }
        for (let i = 0; i < contentDataApplicant.length; i++) { 
            if(document.querySelector("[data-add='"+contentDataApplicant[i][1]+"']").value == ""){
                document.querySelector("[data-add='"+contentDataApplicant[i][1]+"']").style.backgroundColor = "red";
                goUrl = false;
            }else{
                url += "&"+contentDataApplicant[i][1]+"="+document.querySelector("[data-add='"+contentDataApplicant[i][1]+"']").value;
            }
        }
        for (let i = 0; i < contentDataApplication.length; i++) { 

            if(document.querySelector("[data-add='"+contentDataApplication[i][1]+"']").value == ""){
                document.querySelector("[data-add='"+contentDataApplication[i][1]+"']").style.backgroundColor = "red";
                goUrl = false;
            }else{
                url += "&"+contentDataApplication[i][1]+"="+document.querySelector("[data-add='"+contentDataApplication[i][1]+"']").value;
            }

        }
        console.log(goUrl);
        if(goUrl){
            xhr.open("GET", "https://elec-commerc.com/static/api/addmain/?keys="+keys+"&add=application"+url, false);

            try {
                xhr.send();
                if (xhr.status != 200) {
                    console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`);
                } else {
                    objAllApi = JSON.parse(xhr.response);
                    console.log(objAllApi)
                    
                }
            } catch(err) { // для отлова ошибок используем конструкцию try...catch вместо onerror
                    console.log("Запрос не удался");
            }
        }


    }

    return (
    <div>
        <header class="jam-header">
            {getHead(contentHead)}

            <ul class="jam-ul-header">
                <li class="jam-li-header"> <div class="jam-button" data-id="me" onClick={dataMe} > <div class="jam-div-button">{objAllApi.user.name} {objAllApi.user.surname}</div></div><img class="jam-img-icon-header" src="https://avatars.mds.yandex.net/i?id=4f7586d49edaa427e07a8819562fc284_l-5248434-images-thumbs&n=13" /></li> 
                <li class="jam-li-header"> <div class="jam-button" onClick={dataExit} > <div class="jam-div-button">Выход</div></div> </li> 
            </ul>
    </header>
    <div class="jam-main">
        <div class="mobile-table">
            <table class="iksweb">
            <tbody>
                <tr><td><div class="jam-td-mob-name">Контрагент:</div></td></tr>
                {getContentCounter(contentDataCounter)}
                <tr><td><div class="jam-td-mob-name">Заявитель:</div></td></tr>
                {getContentApplicant(contentDataApplicant)}
                <tr><td><div class="jam-td-mob-name">Заявка:</div></td></tr>
                {getContentApplication(contentDataApplication)}
                <tr><div class="jam-tb-control"> <div class="jam-button-tb" onClick={addMain} > <div class="jam-tb-button">Добавить</div></div> </div></tr>
            </tbody>
            </table>
            </div>
        </div>
            

        <div>
            
        </div>
    </div>
    );
}

export default AddMain;
