import logo from './logo.svg';
import './App.css';
import './Main.css';
import './Media.css';
import './Counterparty.css';
import React from 'react';

function Counterparty(props) {
    var saveRoll = [];
    var saveSubdiv = [];

    var addRoll = [];
    var addSubdiv = [];
    let keys = localStorage.getItem("key_s");

    if(!keys){
        console.log(keys);
        window.location.href = "/";        
    }
    else{
        console.log(keys);
    }
    var objAllApi;
    var contentHead;
    var contentAddMain;
    var contentDataMain;
    var contentDataMainOnly;
    var contentDataRoll;
    var contentDataApplicant;
    let xhr = new XMLHttpRequest();
    //xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.open("GET", "https://elec-commerc.com/static/api/datacounterparty/?keys="+keys, false);
    //xhr.responseType = "json";
    try {
        xhr.send();
        if (xhr.status != 200) {
            console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`);
        } else {
            objAllApi = JSON.parse(xhr.response);
            contentHead = objAllApi.user.roll;
            contentAddMain = objAllApi.user.roll;
            contentDataMain = objAllApi.counterparty;
            contentDataRoll = objAllApi.roll;
            //contentDataApplicant = objAllApi.applicant;
            console.log(contentDataApplicant);
            //console.log(JSON.parse(objAllApi));
        }
    } catch(err) { // для отлова ошибок используем конструкцию try...catch вместо onerror
            console.log("Запрос не удался");
    }

    function dataSetMenu(event){
        window.location.href = "/"+event.target.getAttribute("data-id")+"/"; 
        console.log(event.target.getAttribute("data-id"));
    }
    function dataSet(event){
        console.log(props.objReg[0].apiSess);
    }

    function dataMe(event){
        console.log(event.target);
        //setName(event.target.value);  
    }
    
    function dataExit(event) {
        localStorage.removeItem("key_s");
        window.location.href = "/";
    }
    function dataMenuMobile(event){
        console.log(document.getElementById("menu-mob") );
        let atr = document.getElementById("menu-mob") ;
        console.log(atr.getAttribute("data-menu-mobile") );
        if(atr.getAttribute("data-menu-mobile") == "close"){
            atr.dataset.menuMobile = "open";
            atr.innerHTML = "X";
            document.getElementById("header-control").style.display = "flex";
        }else{
            atr.dataset.menuMobile = "close";
            atr.innerHTML = "&#9776;";
            document.getElementById("header-control").style.display = "none";
        }
    }


    function saveCounterparty(event){
        
        //id_onec name date_contract number_contract
        let id_onec = document.querySelector("[data-id_onec='"+event.target.getAttribute("data-counterparty-id")+"']").value;
        let name = document.querySelector("[data-name='"+event.target.getAttribute("data-counterparty-id")+"']").value;
        let date_contract = document.querySelector("[data-date_contract='"+event.target.getAttribute("data-counterparty-id")+"']").value;
        let number_contract = document.querySelector("[data-number_contract='"+event.target.getAttribute("data-counterparty-id")+"']").value;


        //saveRoll[event.target.getAttribute("data-counterparty-id")]
        //console.log(saveRoll[event.target.getAttribute("data-counterparty-id")])
        let url = "";
        url += "&id_onec="+id_onec;
        url += "&name="+name;
        url += "&date_contract="+date_contract;
        url += "&number_contract="+number_contract;

        console.log(url)
        xhr.open("GET", "https://elec-commerc.com/static/api/datacounterparty/?keys="+keys+"&update=counterparty&tbid="+event.target.getAttribute("data-counterparty-id")+url, false);
        //xhr.responseType = "json";
        try {
            xhr.send();
            if (xhr.status != 200) {
                console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`);
            } else {
                window.location.href = "/counterparty/"; 
                //console.log(JSON.parse(objAllApi));
            }
        } catch(err) { // для отлова ошибок используем конструкцию try...catch вместо onerror
                console.log("Запрос не удался");
        }


        
    }
    function addCounterparty(event){
        //data-add="name" data-add="patronymic" data-add="surname" data-add="phone" data-add="mail" data-add="active" data-add="roll" data-add="subdiv"
        ////id_onec name date_contract number_contract
        let id_onec = document.querySelector("[data-add=id_onec]").value;
        let name = document.querySelector("[data-add='name']").value;
        let date_contract = document.querySelector("[data-add='date_contract']").value;
        let number_contract = document.querySelector("[data-add='number_contract']").value;
        let url = "";
        if(name !== "" && date_contract !== "" && number_contract !== "" ){
            url += "&id_onec="+id_onec;
            url += "&name="+name;
            url += "&date_contract="+date_contract;
            url += "&number_contract="+number_contract;
            console.log(url)
            xhr.open("GET", "https://elec-commerc.com/static/api/datacounterparty/?keys="+keys+"&add=counterparty"+url, false);
            //xhr.responseType = "json";
            try {
                xhr.send();
                if (xhr.status != 200) {
                    console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`);
                } else {
                    //window.location.href = "/counterparty/"; 
                    //console.log(JSON.parse(objAllApi));
                }
            } catch(err) { // для отлова ошибок используем конструкцию try...catch вместо onerror
                    console.log("Запрос не удался");
            }
        }else{
            console.log("stop");
        }

    }
    function selectDivRoll(event){
        console.log(event.target.getAttribute("data-roll") );
        console.log(event.target.value);
        saveRoll[event.target.getAttribute("data-roll")] = event.target.value;
        console.log(saveRoll);
    }
    function selectDivSubdiv(event){
        console.log(event.target.getAttribute("data-subdiv") );
        console.log(event.target.value);
        saveSubdiv[event.target.getAttribute("data-subdiv")] = event.target.value;
        console.log(saveSubdiv);
    }

    function selectAddDivRoll(event){
        console.log(event.target.getAttribute("data-roll") );
        console.log(event.target.value);
        addRoll[event.target.getAttribute("data-roll")] = event.target.value;
        console.log(addRoll);
    }
    function selectAddDivSubdiv(event){
        console.log(event.target.getAttribute("data-subdiv") );
        console.log(event.target.value);
        addSubdiv[event.target.getAttribute("data-subdiv")] = event.target.value;
        console.log(addSubdiv);
    }
    let getHead = contentHead => {
        console.log(contentHead);
        let content = [];
        if(contentHead == 0 || contentHead == 5 || contentHead == 6){
            content.push( 
                <ul class="jam-ul-header">    
                    <li class="jam-li-header"> <div class="jam-button" data-id="main" onClick={dataSetMenu} > <div data-id="main" class="jam-div-button">Заявки</div></div> </li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="roll" onClick={dataSetMenu} > <div data-id="roll" class="jam-div-button">Роли</div></div> </li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="subdivision" onClick={dataSetMenu} > <div data-id="subdivision" class="jam-div-button">Подразделения</div></div></li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="users" onClick={dataSetMenu} > <div data-id="users" class="jam-div-button">Сотрудники</div></div></li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="applicant" onClick={dataSetMenu} > <div data-id="applicant" class="jam-div-button">Заявители</div></div></li>
                    <li class="jam-li-header"> <div class="jam-button" data-id="counterparty" onClick={dataSetMenu} > <div data-id="counterparty" class="jam-div-button">Контрагенты</div></div></li>
                    <li class="jam-li-header">  <input class="jam-input" type="text" placeholder="поиск" /> </li>
                </ul>
            );
        }
        return content;
    }

    let getAddMain = contentAddMain => {
        console.log(contentAddMain);
        let content = [];
        if(contentHead == 0 || contentHead == 5 || contentHead == 6){
            content.push( 
                <div class="jam-bottom">
                    <ul class="jam-ul-bottom">
                        <li class="jam-li-header"> <div class="jam-button" onClick={dataExit} > <div class="jam-div-button">Добавить</div></div> </li> 
                    </ul>
                </div>
            );
        }
        return content;
    }
    // function setInput(event){
    //     return "ff";
    // }
    // let getDivSubdivSelect = contentDataSubdivision => { 
    //     let content = []; 
    //     for (let i = 0; i < contentDataSubdivision.length; i++) { 
    //         console.log(contentDataSubdivision[i]);
    //         content.push ( <option data-id={contentDataSubdivision[i].id} value={contentDataSubdivision[i].id} >{contentDataSubdivision[i].name}</option> ); 
    //     } 
    //     return content; 
    // }

    // let getDivRollSelect = contentDataRoll => { 
    //     let content = []; 
    //     console.log("test",contentDataRoll);
    //     for (let i = 0; i < contentDataRoll.length; i++) { 
    //         content.push ( <option data-id={contentDataRoll[i].id} value={contentDataRoll[i].id} >{contentDataRoll[i].name}</option> ); 
    //     } 
    //     return content; 
    // }

    let getContent = contentDataMain => {
        console.log(contentDataMain.length);
        let content = [];
        content.push(             
            <thead>
                <tr>
                    <th>Внешний код</th>
                    <th>Наименование</th>
                    <th>Дата договора</th>
                    <th>Номер договора</th>
                    <th></th>
                </tr>
            </thead>
        );
        for (let i = 0; i < contentDataMain[0].length; i++) {
            const item = contentDataMain[0][i];

            // let subDiv = [];
            // subDiv = contentDataSubdivision.filter(function(subdiv) {
            //     return subdiv.id === item.subdivision_id;
            // })[0];

            // let rollDiv = [];
            // rollDiv = contentDataRoll.filter(function(rolldv) {
            //     return rolldv.id === item.roll_id;
            // })[0];

            let nname = item.name;
            content.push(
                <tbody>
                    <tr><td><div class="jam-td-mob-name">Внешний код:</div><div class="jam-tb-control"><input data-id_onec={item.id} class="jam-input-tb" defaultValue={item.id_onec} /> </div></td>
                    <td><div class="jam-td-mob-name">Наименование:</div><div class="jam-tb-control"><input data-name={item.id} class="jam-input-tb" defaultValue={item.name}/>  </div></td>
                    <td><div class="jam-td-mob-name">Дата договора:</div><div class="jam-tb-control"><input type="date" data-date_contract={item.id} class="jam-input-tb" defaultValue={item.date_contract}/> <></>  </div></td>
                    <td><div class="jam-td-mob-name">Номер договора:</div><div class="jam-tb-control"><input data-number_contract={item.id} class="jam-input-tb" defaultValue={item.number_contract}/> <></>  </div></td>
                    <td><div class="jam-tb-control"> <div class="jam-button-tb" onClick={saveCounterparty} > <div data-counterparty-id={item.id} class="jam-tb-button">Сохранить</div></div> </div></td>
                    
                    </tr>
                </tbody>
            );
        }
        content.push(
            <tbody>
                <tr><td><div class="jam-td-mob-name">Внешний код:</div><div class="jam-tb-control"><input data-add="id_onec" class="jam-input-tb" />  </div></td>
                    <td><div class="jam-td-mob-name">Наименование:</div><div class="jam-tb-control"><input data-add="name" class="jam-input-tb" />  </div></td>
                    <td><div class="jam-td-mob-name">Дата договора:</div><div class="jam-tb-control"><input type="date" data-add="date_contract" class="jam-input-tb" />  </div></td>
                    <td><div class="jam-td-mob-name">Номер договора:</div><div class="jam-tb-control"><input data-add="number_contract" class="jam-input-tb" />  </div></td>
                    <td><div class="jam-tb-control"> <div class="jam-button-tb" onClick={addCounterparty} > <div class="jam-tb-button">Добавить</div></div> </div></td>
                    </tr>
            </tbody>
        );
        return content;
    }

    

    return (
    <div>
        <header id="header-control" class="jam-header">
            {getHead(contentHead)}

            <ul class="jam-ul-header">
                <li class="jam-li-header"> <div class="jam-button" data-id="me" onClick={dataMe} > <div class="jam-div-button">{objAllApi.user.name} {objAllApi.user.surname}</div></div><img class="jam-img-icon-header" src="https://avatars.mds.yandex.net/i?id=4f7586d49edaa427e07a8819562fc284_l-5248434-images-thumbs&n=13" /></li> 
                <li class="jam-li-header"> <div class="jam-button" onClick={dataExit} > <div class="jam-div-button">Выход</div></div> </li> 
            </ul>
    </header>
    <div class="jam-header-control">
            <div class="jam-header-control-op">
                <div class="jam-button-header" onClick={dataMenuMobile} > <div id="menu-mob" data-menu-mobile="close" class="jam-div-button">&#9776;</div></div>
            </div>
        </div>
    <div class="jam-main">
        <div class="mobile-table">
            <table class="iksweb">
            {getContent(contentDataMain)}

            </table>
            </div>
        </div>


        <div>
            
        </div>
    </div>
    );
}

export default Counterparty;
